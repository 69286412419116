const documentBody = document.body;
const hotjarSelector = "._hj-YR-2H__Feedback__container";

/*
  Helper fn to manipulate hotjar z-index for menu dropdown
*/
export function showHotjar() {
  const hotjarElement = document.querySelector(hotjarSelector);
  const filterElement = document.querySelector('[data-testid = "Overlay"]');
  if (hotjarElement && !filterElement) {
    hotjarElement.style.removeProperty("z-index");
  }
}

export function hideHotjar() {
  const hotjarElement = document.querySelector(hotjarSelector);
  if (hotjarElement) {
    hotjarElement.style.zIndex = "199";
  }
}

export function toggleHotjar() {
  if (documentBody.classList.contains("menu--open")) {
    hideHotjar();
  } else {
    showHotjar();
  }
}
