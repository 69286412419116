import FeatureFlags from "@arcteryx/js-feature-flags";

export const FeatureSet = FeatureFlags.define([
  "RedirectJapanToArcteryxJP",
  "enableOutletFlashSaleModal",
  "enableSanityPromoBar",
  "enableOptimizelyClient",
  "useAlgoliaPreprod",
  "enableSanityStaticSearchLinks",
  "useGraphQLEndpoint",
]);
