import DataLayer from "@arcteryx/data-layer";
import { buildProductModel, buildRefinementsModel } from "./AnalyticsHelpers";

export const ANALYTICS_DEBOUNCE_MS = 1500;

export const searchAnalyticsEvent = props => {
  const { searchTermEntered, searchTermSuggested, staticTerm, termCount, terms } = props;
  DataLayer.Event.onsiteSearchPerformed(searchTermEntered, searchTermSuggested, staticTerm, termCount, terms);
};

export const listingViewedAnalyticsEvent = (evt, state, resetSearch, country, language) => {
  if (evt?.hits?.length > 0 && !resetSearch) {
    const productsModel = evt?.hits?.map(
      (hit, index) => new DataLayer.Data.ListingProduct(buildProductModel(hit, country, language), index)
    );
    const formattedRefinements = buildRefinementsModel(
      state?.disjunctiveFacetsRefinements,
      state?.hierarchicalFacetsRefinements
    );
    const refinementsModel = formattedRefinements.map(item => new DataLayer.Data.Refinements(item));
    DataLayer.Event.listingViewed(productsModel, refinementsModel, evt?.query);
  } else {
    DataLayer.Event.listingViewed([], [], evt?.query);
  }
};

export const setLinkClickedAnalyticsListeners = (element, selector, linkType) => {
  element.querySelectorAll(selector).forEach(linkItem => {
    const onLinkClick = () => {
      const dataLinkId = linkItem.dataset.linkid;
      const { url, analyticslabel: text } = linkItem.dataset;
      const link = new DataLayer.Data.Link({
        type: linkType,
        id: dataLinkId,
        text,
        url,
      });
      DataLayer.Event.linkClicked(link);
    };
    linkItem.addEventListener(
      "click",
      ({ pointerType }) => {
        if (pointerType === "mouse") {
          onLinkClick();
        }
      },
      { passive: true }
    );
    linkItem.addEventListener(
      "touchstart",
      () => {
        onLinkClick();
      },
      { passive: true }
    );
    linkItem.addEventListener(
      "contextmenu",
      () => {
        onLinkClick();
      },
      { passive: true }
    );
  });
};

export const setNavigationLinkClickedAnalyticsListeners = (element, selector) => {
  element.querySelectorAll(selector).forEach(linkItem => {
    const onNavigationLinkClick = () => {
      const dataLinkId = linkItem.dataset.linkid;
      const link = new DataLayer.Data.Link({
        id: dataLinkId,
      });

      if (dataLinkId !== "") {
        DataLayer.Event.navigationLinkClicked(link);
      }
    };

    // Track which nav link is right clicked
    linkItem.addEventListener(
      "contextmenu",
      () => {
        onNavigationLinkClick();
      },
      { passive: true }
    );

    // Track which nav links are clicked
    linkItem.addEventListener(
      "click",
      ({ pointerType }) => {
        if (pointerType === "mouse") {
          onNavigationLinkClick();
        }
      },
      { passive: true }
    );

    linkItem.addEventListener(
      "touchstart",
      () => {
        onNavigationLinkClick();
      },
      { passive: true }
    );
  });
};

export const linkClickedAnalyticsEvent = props => {
  const { type, id, text, url } = props;
  DataLayer.Event.linkClicked({
    model: {
      linkType: type,
      linkId: id,
      linkText: text,
      linkDestination: url,
    },
  });
};
