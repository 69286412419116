import config from "../../common/config";

const loadKasada = () => {
  if (window && (!window.KPSDK || !window.KPSDK.isReady())) {
    document.addEventListener("kpsdk-load", () => {
      try {
        window.KPSDK.configure([
          {
            protocol: "https",
            method: "*",
            domain: window.location.hostname,
            path: "/api/mcgql",
          },
        ]);
      } catch (e) {
        console.error("error", e);
      }
    });

    const script = document.createElement("script");
    script.src = config.getKasadaSecretPath();
    script.async = true;
    document.head.appendChild(script);
  }
};

export const loadBotDefence = () => {
  if (KASADA_ENABLED === "true") {
    loadKasada();
  }
};
