const generateNavLinkParams = {
  className: "nav-item",
  href: "/",
  blockArr: ["nav-item--block"],
  onlyArr: ["nav-item--only"],
  dataPrefix: "nav",
  text: "Home",
  dataLinkId: ["home"],
  tabIndex: 0,
  hide: false,
};

/**
 *
 * @typedef { Object } generateNavLinkParams
 * @property { string } className - The class name of the nav item also the name of the asset in the s3 bucket
 * @property { string } href - The href of the nav item
 * @property { string[] } blockArr - e.g. ["CA-block"]
 * @property { string[] } onlyArr - e.g. ["CA-only"]
 * @property { string } dataPrefix - The data prefix of the nav item
 * @property { string } text - The text of the nav item
 * @property { string[] } dataLinkId - e.g. ["men", "example"] -> output men:example
 * @property { number } tabIndex - The tab index of the nav item
 * @property { boolean } hide - Whether the nav item should be hidden
 * @returns { string } - The generated nav link
 */

export const generateNavLink = ({
  className,
  href = null,
  blockArr = [],
  onlyArr = [],
  dataPrefix = null,
  text,
  dataLinkId = [],
  tabIndex = null,
  hide = false,
}) => {
  const blockStr = blockArr.join(" ");
  const onlyStr = onlyArr.join(" ");
  const dataLinkIdStr = dataLinkId.join(":");

  if (hide) {
    return "";
  }

  const retString = `
    <li class="${className} ${blockStr} ${onlyStr}" ${tabIndex ? `tabindex=${tabIndex}` : ""}>
      ${
        href === null
          ? `<span ${dataPrefix ? `data-prefix=${dataPrefix}` : ""}>${text}</span>`
          : `<a href="${href}" ${dataPrefix ? `data-prefix=${dataPrefix}` : ""} ${
              dataLinkId.length ? `data-linkid=${dataLinkIdStr}` : ""
            }>${text}</a>`
      }
    </li>`;

  return retString;
};
