import { FeatureSet } from "./features";

class Config {
  getMagentoUrl() {
    return "/api/mcgql";
  }

  getKasadaSecretPath() {
    const prefix = NODE_ENV === "development" ? "https://o-stage.arcteryx.com" : "";
    return `${prefix}/149e9513-01fa-4fb0-aad4-566afd725d1b/2d206a39-8ed7-437e-a3be-862e0f06eea3/p.js`;
  }

  isSanityPromoBarEnabled() {
    return ENABLE_SANITY_PROMO_BAR === "true" || FeatureSet.enableSanityPromoBar();
  }

  getSanityProjectId() {
    return SANITY_PROJECT_ID;
  }

  getSanityDataset() {
    return SANITY_DATASET;
  }

  isOptimizelyClientEnabled() {
    return ENABLE_OPTIMIZELY_CLIENT === "true" || FeatureSet.enableOptimizelyClient();
  }

  getOptimizelySdkKey() {
    return OPTIMIZELY_SDK_KEY;
  }
}

export default new Config();
