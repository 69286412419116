import {
  appendCountryLanguage,
  updateClassNames,
  isUserLoggedIn,
  updateCartCount,
  initializeLightbox,
  hideElementInIframe,
  addHoverForNav,
  removeAllHoverForNav,
  toggleHoverForNav,
  removeHoverForNav,
  switchToCheckoutHeader,
  listenForImportantMessage,
  trackClick,
  loadScript,
  appendScriptToBody,
  injectURWFont,
  getCountry,
  getLanguage,
  buildHeadMetaItems,
  disableAdobeLaunch,
  injectPromobarMessage,
  isNavDropdownEnabled,
  toggleRegularPage,
  hideInvalidElementsInWebView,
  isWebview,
  getOneTrustLang,
} from "./GeneralHelpers";
import { toggleHotjar } from "./HotjarHelpers";
import Auth from "./Authentication";
import headerTemplateHtml from "../templates/header-sale";
import ArcWebSettings from "./Arcteryx";
import { FeatureSet } from "../../common/features";
import { isStaffUser } from "@arcteryx/js-auth";
import { setLinkClickedAnalyticsListeners, setNavigationLinkClickedAnalyticsListeners } from "./Analytics";
import { loadBotDefence } from "../helpers/botDefence";

class HeaderSale extends HTMLElement {
  constructor() {
    super();

    if (!isWebview()) {
      this.root = this.attachShadow({ mode: "open" });
      loadBotDefence();
    }
  }

  connectedCallback() {
    this.connectedCallbackRequired();

    if (isWebview()) {
      hideInvalidElementsInWebView();
    } else {
      this.connectedCallbackUI();
      this.refreshJwtInterval = Auth.refreshJWT(this.root, this.market);
    }
  }

  disconnectedCallback() {
    clearInterval(this.refreshJwtInterval);
  }

  connectedCallbackRequired() {
    this.country = getCountry(this);
    this.language = getLanguage(this);
    this.fdp = this.hasAttribute("fdp") && this.getAttribute("fdp") === "true";
    this.disableSearchCss = this.hasAttribute("disableSearchCss") && this.getAttribute("disableSearchCss") === "true";
    this.season = this.hasAttribute("season") ? this.getAttribute("season") : "S";
    this.year = this.hasAttribute("year") ? this.getAttribute("year") : new Date().getFullYear().toString();
    this.market = "sale";

    // SWAT-8244 add language in html tag
    document.getElementsByTagName("html")[0].lang = getOneTrustLang(this.language);

    if (!this.fdp) {
      ArcWebSettings.init("SALE", this.season, this.year, this.language, this.country);
    }

    // Initialize configuration for ForgeRock
    Auth.init(this.country, this.language, DEPLOY_ENV);

    // Load Adobe Launch ADOBE_LAUNCH_URL_SALE is set in webpack.common.js
    if (!this.fdp && ADOBE_LAUNCH_URL_SALE && !disableAdobeLaunch()) {
      loadScript(ADOBE_LAUNCH_URL_SALE);
    }

    if (!this.fdp && (typeof ARCTERYX === "undefined" || typeof ARCTERYX.analytics === "undefined")) {
      loadScript(`${SCRIPTS_URL}/js/analytics.min.js`);
    }
    appendScriptToBody(`${SCRIPTS_URL}/js/forter.js`, FORTER_ID);
  }

  connectedCallbackUI() {
    // Add header content to body
    const headerTemplate = document.createElement("template");
    const translations = require(`../../utils/translation-data_${this.language}.js`).data;
    headerTemplate.innerHTML = headerTemplateHtml({
      data: translations,
      country: this.country,
      language: this.language,
    });
    this.root.appendChild(headerTemplate.content.cloneNode(true));

    buildHeadMetaItems();
    this.bindEvents(this.root);
    this.bindListeners(this.root);
    appendCountryLanguage(this.root, this.country, this.language);
    updateClassNames(this.root, ".SALE", this.country, this.language);
    isUserLoggedIn(this.root, ".SALE");
    updateCartCount(this.root, this.market, this.country);
    initializeLightbox(this.root);
    if (!this.fdp) {
      // FDP pages get this font loaded through js-helpers <GlobalStyles />
      injectURWFont();
    }
    hideElementInIframe(this.root, ".SALE");
    switchToCheckoutHeader(this.root);
    injectPromobarMessage(this.root, this.market, this.country, this.language);
  }

  createSearchContainer() {
    const searchContainerName = "DeadBirdSearchContainer";
    let searchContainer = document.getElementById(searchContainerName);
    if (!searchContainer) {
      searchContainer = document.createElement("div");
      searchContainer.id = searchContainerName;
      this.insertAdjacentElement("afterend", searchContainer);
    } else {
      searchContainer = document.getElementById(searchContainerName);
    }
    return searchContainer;
  }

  async loadSearch(el) {
    this.search = await import(/* webpackChunkName: "search-outdoor" */ "../../search/index.js");
    const searchIconEl = el.querySelector(".header-search");
    searchIconEl.style.opacity = 1;
    searchIconEl.addEventListener(
      "click",
      () => {
        this.openSearch(el);
      },
      true
    );
    document.addEventListener("search-closed", () => {
      toggleRegularPage(true);
    });
    if (window.location.search.match(/search=/)) {
      return this.openSearch(el);
    }
    searchIconEl.click();
  }

  openSearch(el) {
    toggleRegularPage(false);
    const searchContainer = this.createSearchContainer();
    const searchIconEl = el.querySelector(".header-search");
    searchContainer.classList.toggle("open");
    const indexConstructor = {
      market: "outlet",
      language: this.language,
      country: this.country,
    };
    this.search.InitSearch(el.searchIsOpen, searchContainer, indexConstructor);
    searchIconEl.style.removeProperty("opacity");
    el.firstChild.classList.toggle("search-mode");
    document.body.classList.toggle("search-mode");
  }

  createModalContainer() {
    const modalContainerName = "outletModalContainer";
    let modalContainer = document.getElementById(modalContainerName);
    if (!modalContainer) {
      modalContainer = document.createElement("div");
      modalContainer.id = modalContainerName;
      this.insertAdjacentElement("afterend", modalContainer);
    } else {
      modalContainer = document.getElementById(modalContainerName);
    }
    return modalContainer;
  }

  async loadModal(el) {
    this.modal = await import(/* webpackChunkName: "Modal" */ "../../modal/index.js");
    const stateFromHeader = {
      market: this.market,
      language: this.language,
      country: this.country,
    };
    const modalContainer = this.createModalContainer();
    this.modal.InitModal({
      isOpen: el.modalIsOpen,
      renderElement: modalContainer,
      stateFromHeader,
      isClosable: false,
    });
  }

  bindEvents(el) {
    // Open Flash Sale modal except for staff
    if (FeatureSet.enableOutletFlashSaleModal() && !isStaffUser()) {
      this.loadModal(el);
    }

    setLinkClickedAnalyticsListeners(el, ".sale__banner .header_content ul li a", "header");

    if (window.location.search.match(/search=/) && !document.querySelector("arcteryx-search")) {
      this.loadSearch(el);
    }

    el.querySelector(".header-search").addEventListener(
      "click",
      () => {
        this.loadSearch(el);
      },
      { once: true }
    );

    el.querySelectorAll(".header .flag").forEach(el => {
      el.classList.add(`flag-${this.country.toUpperCase()}`);
      el.addEventListener("click", () => {
        const flagEv = new Event("ARCTERYXUICOMPONENTS.localeClick", {
          bubbles: true,
          cancelable: false,
        });
        flagEv.initEvent("ARCTERYXUICOMPONENTS.localeClick", true, true);
        this.dispatchEvent(flagEv);
      });
    });
  }

  bindListeners(el) {
    document.addEventListener("search-closed", () => {
      el.querySelector(".header-search").style.opacity = 1;
      document.body.classList.toggle("search-mode");
      el.firstChild.classList.toggle("search-mode");
      document.getElementById("DeadBirdSearchContainer").classList.toggle("open");
    });
    setNavigationLinkClickedAnalyticsListeners(el, "nav .sub-menu .container ul li a");
    el.querySelectorAll("nav .sub-menu .container ul li a").forEach(navLink => {
      if (window.innerWidth >= 1300) {
        // Show feature image on link hover
        const featuredEl = this.getClosest(navLink, ".container").querySelector(".feature .content");
        navLink.addEventListener(
          "mouseover",
          () => {
            this.getAndSetFeatureImage(navLink);
            featuredEl.classList.add("hovered");
          },
          { passive: true }
        );
        navLink.addEventListener(
          "mouseleave",
          () => {
            featuredEl.classList.remove("hovered");
          },
          { passive: true }
        );
      }
    });

    el.querySelectorAll("nav .sub-menu").forEach(dropDown => {
      dropDown.addEventListener(
        "mouseover",
        () => {
          addHoverForNav(dropDown, el, "SALE");
          toggleHotjar();
        },
        { passive: true }
      );
      dropDown.addEventListener(
        "mouseleave",
        () => {
          removeAllHoverForNav(el, "SALE");
          toggleHotjar();
          if (dropDown.querySelectorAll(".shopmore .mobilefly li").length) {
            const defaultFeature = dropDown.querySelectorAll(".shopmore .mobilefly li")[1].querySelector("a");
            setTimeout(() => {
              this.getAndSetFeatureImage(defaultFeature);
            }, 50);
          }
        },
        { passive: true }
      );
    });
    if (isNavDropdownEnabled(this.country)) {
      if (window.innerWidth < 769) {
        el.querySelector("nav #men.head").addEventListener(
          "click",
          e => {
            toggleHoverForNav(e.target, el, "SALE");
            removeHoverForNav(el.querySelector("nav #women.head"));
            toggleHotjar();
          },
          { passive: true }
        );
        el.querySelector("nav #women.head").addEventListener(
          "click",
          e => {
            toggleHoverForNav(e.target, el, "SALE");
            removeHoverForNav(el.querySelector("nav #men.head"));
            toggleHotjar();
          },
          { passive: true }
        );
      } else {
        el.querySelectorAll("nav .head").forEach(dropDown => {
          dropDown.addEventListener("click", e => {
            if (e.target.id == "explore") {
              e.preventDefault();
            }
          });
          dropDown.addEventListener(
            "mouseover",
            () => {
              addHoverForNav(dropDown, el, "SALE");
              toggleHotjar();
            },
            { passive: true }
          );
          dropDown.addEventListener(
            "mouseleave",
            () => {
              removeAllHoverForNav(el, "SALE");
              toggleHotjar();
            },
            { passive: true }
          );
        });
      }
    } else {
      const headerMenBtn = this.root.querySelector("#men.head > a");
      const headerWomenBtn = this.root.querySelector("#women.head > a");
      headerMenBtn.classList.add("disable-dropdown");
      headerWomenBtn.classList.add("disable-dropdown");
    }
    if (isNavDropdownEnabled(this.country) && "ontouchstart" in document.documentElement) {
      el.querySelectorAll("nav .head a, nav a.head").forEach(navLink => {
        navLink.addEventListener("touchstart", e => {
          e.preventDefault();
        });
      });
    }
    // Set the default feature image
    el.querySelectorAll(".sub-menu").forEach(single => {
      const navItemClassLists = Array.from(single.querySelectorAll(".mobilefly li")).map(navItemEl =>
        Array.from(navItemEl.classList)
      );
      const navItemIndex = navItemClassLists.findIndex(
        navItemClassList => !navItemClassList.includes(`${this.country.toUpperCase()}-block`)
      );
      const defaultFeature = single.querySelectorAll(".mobilefly li")[navItemIndex].querySelector("a");

      this.getAndSetFeatureImage(defaultFeature);
    });
    const divOverlay = document.createElement("div");
    divOverlay.id = "content--overlay";
    divOverlay.classList.add("overlay-dark--full");
    divOverlay.style.display = "none";
    divOverlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    document.body.appendChild(divOverlay);

    document.querySelector("#content--overlay").addEventListener("touchstart", () => {
      removeAllHoverForNav(el, "SALE");
    });

    window.addEventListener("cart-update", () => {
      updateCartCount(this.root, this.market, this.country);
    });

    this.importantMessageLightBox = listenForImportantMessage();
  }

  getClosest(elem, selector) {
    let newElem = elem;
    // Fallback for IE
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector;
    }
    for (; newElem && newElem !== document; newElem = newElem.parentNode) {
      if (newElem.matches(selector)) {
        return newElem;
      }
    }
    return null;
  }

  getAndSetFeatureImage(hoveredEl) {
    let featureImageBox, imageName, imageText;
    if (!hoveredEl.classList.contains("social-na")) {
      if (hoveredEl.parentNode.classList) {
        featureImageBox = this.getClosest(hoveredEl, ".container").querySelector(".feature .content");
      }
      let gender = "";
      if (hoveredEl.getAttribute("data-prefix") !== null) {
        gender = `${hoveredEl.getAttribute("data-prefix")}-`;
      }

      featureImageBox.href = hoveredEl.getAttribute("href");
      imageName = hoveredEl.parentNode.classList
        .toString()
        .replace("sectionhead", "")
        .replace("style-scope", "")
        .replace("arcteryx-sale-header", "")
        .replace("gift-card-countries-only", "")
        .trim();
      imageName = imageName.split(" ")[0];
      imageText = hoveredEl.textContent;

      if (hoveredEl.parentNode.classList.contains("rocksolid") && this.country != "US") {
        imageText = hoveredEl.parentNode.nextElementSibling.textContent;
        imageName = hoveredEl.parentNode.nextElementSibling.className;
        gender = `${hoveredEl.parentNode.nextElementSibling.querySelector("a").getAttribute("data-prefix")}-`;
      }

      const imageUrl = `https://images-dynamic-arcteryx.imgix.net/nav/feature/outlet/s20/${gender}${imageName}.jpg?auto=format`;
      setTimeout(() => {
        featureImageBox.style.backgroundImage = `url(${imageUrl})`;
        featureImageBox.querySelector("h6").textContent = imageText;
      }, 300);
    }
  }
}

export default HeaderSale;
