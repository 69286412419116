import { NULL_PRICE } from "../constants/nullPrice";
import { buildPriceModel } from "@arcteryx/js-price";

const calculateDiscountPricePercent = (price, discountedPrice) =>
  (((price - discountedPrice) * 100) / price).toFixed(2);

export const buildProductModel = (searchResult, country, language) => {
  // Price
  // Check if formattedNumber exists, if not create the number
  const priceFormatted = !searchResult?.price?.formattedNumber
    ? buildPriceModel(searchResult?.price, country, language)
    : searchResult?.price;
  const price = priceFormatted?.formattedNumber;

  // DiscountPrice
  // Check if price is null price of 999999
  const nullDiscountPrice = searchResult?.discountPrice === NULL_PRICE;
  const discountPriceFormatted =
    !searchResult?.discountPrice?.formattedNumber && !nullDiscountPrice
      ? buildPriceModel(searchResult?.discountPrice, country, language)
      : searchResult?.discountPrice;
  const discountPrice = discountPriceFormatted.formattedNumber;

  return {
    id: searchResult?.objectID || searchResult?.id,
    analyticsName: searchResult?.analyticsName || searchResult?.name,
    season: "",
    selectedColour: {
      label: searchResult?.colors?.filter(item => item.Selected)[0]?.Descr || searchResult?.colourOptions?.selected,
    },
    price,
    discountPrice,
    savingsPercentage: searchResult?.isDiscounted ? calculateDiscountPricePercent(price, discountPrice) : 0,
    market: searchResult?.market,
    currencyCode: searchResult?.currencyCode || searchResult?.currency,
    ratingCount: searchResult?.review_count || searchResult?.reviewCount || 0,
    ratingAverage: searchResult?.review_value_rounded || searchResult?.rating || 0,
  };
};

export const buildRefinementsModel = (refinements, genderRefinement) => {
  const keys = Object.keys(refinements);
  const types = {
    "colors.BaseColor": "base_color",
    activities: "Activities",
    "sizes.label": "Sizes",
    review_value_rounded: "Reviews",
  };
  const refinementsList = [];
  if (genderRefinement?.gender?.length > 0) {
    refinementsList.push({
      id: genderRefinement.gender[0],
      catId: "Gender",
    });
  }
  keys.forEach(key => {
    refinements[key].forEach(val => {
      refinementsList.push({
        id: val,
        catId: types[key] ? types[key] : key,
      });
    });
  });
  return refinementsList;
};
