import {
  initAuthentication,
  refreshIdToken,
  isIdTokenExpired,
  isStaffUser,
  clearAuthenticatedUserFromHeaderFooter,
} from "@arcteryx/js-auth";

const getAlphaConfig = (country, language, env, development) => ({
  env,
  country,
  language,
  sdkBaseUrl: AUTH_URL,
  sdkClientId: "arcSdkClient",
  sdkRealmPath: "alpha",
  sdkTree: "ArcLogin2",
  development,
});

const getBravoConfig = (country, language, env, development) => ({
  env,
  country,
  language,
  sdkBaseUrl: AUTH_STAFF_URL,
  sdkClientId: "arcSdkClient",
  sdkRealmPath: "bravo",
  sdkTree: "ARCStaffLogin",
  development,
});

const TWELVE_MINS_MS = 720000;

const Auth = {
  init(country, language, env) {
    const development = process.env.NODE_ENV === "development";
    const FRConfig = isStaffUser()
      ? getBravoConfig(country, language, env, development)
      : getAlphaConfig(country, language, env, development);
    initAuthentication(FRConfig);
  },

  refreshJWT(el, market) {
    // Refreshes every 10mins
    return refreshIdToken({ headerElement: el, market });
  },
};

export default Auth;
