import OUTLETLogo from "../images/OUTLET-logo.svg";
import OUTLETLogoMobile from "../images/OUTLET-MOBILE-logo.svg";
import indexStyles from "../scss/header-sale.scss";
import { isNavDropdownEnabled } from "../components/GeneralHelpers";
import { regearRedirect } from "../helpers/regearRedirect";
import { generateNavLink } from "../../utils/generateNavLink";

const EMEA_Block =
  "AT-block BE-block CZ-block DK-block FI-block FR-block DE-block GB-block IE-block IT-block NO-block PL-block ES-block SE-block CH-block NL-block";

const EMEA_Only =
  "AT-only BE-only CZ-only DK-only FI-only FR-only DE-only GB-only IE-only IT-only NO-only PL-only ES-only SE-only CH-only NL-only";

const blockAllArr = ["US-block", "CA-block", EMEA_Block];

const universalNav = (data, country, language) => `
<div class="sale__banner">
    <div class="header_content">
      <ul class="sale__banner__links">
        <li class="sale__banner__link">
          <a class="universal-black-bar__link" data-analyticsLabel="Arc'teryx" data-linkid="header:outdoor" data-url="arcteryx.com" href="https://arcteryx.com/${country}/${language}">${
  data["Arc'teryx Name"]
}</a>
        </li><li class="sale__banner__link sale__banner__link--active">
          <p class="active">${data["Outlet Name"]}</p>
        </li>
        <li class="sale__banner__link">
            <a class="universal-black-bar__link" data-analyticsLabel="Veilance" data-linkid="header:veilance" data-url="arcteryx.com/veilance" href="https://arcteryx.com/veilance">
                ${data["Veilance Name"]}
            </a>
        </li>
        ${
          country.toLowerCase() === "us" || country.toLowerCase() === "ca"
            ? `<li class="sale__banner__link">
        <a class="universal-black-bar__link" data-analyticsLabel="" data-linkid="header:regear" data-url="${regearRedirect(
          country,
          language
        )}" href="https://www.${regearRedirect(country, language)}">
            ${data.REGEAR}
        </a>
        </li>`
            : ""
        }
      </ul>
    </div>
  </div>
`;

const mensNavDropdown = (data, country, language) => {
  if (!isNavDropdownEnabled(country)) {
    return "";
  }

  return `
  <div class="sub-menu">
      <div class="container">
        <div class="mobilefly">
          <ul>
            <li class="just-landed">
                <a href="/c/mens/just-landed/wid-39r1kkxj" data-prefix="men">${data["Just Landed"]}</a>
            </li>
            <li class="new-for-spring CA-block US-block ${EMEA_Block}">
                <a href="/c/mens/just-added" data-prefix="men">${data["Just Added"]}</a>
            </li>
            <li class="shell-jackets">
                <a href="/c/mens/shell-jackets" data-prefix="men">${data["Shell Jackets"]}</a>
            </li>
            <li class="insulated-jackets">
                <a href="/c/mens/insulated-jackets" data-prefix="men">${data["Insulated Jackets"]}</a>
            </li>
            <li class="pants">
                <a href="/c/mens/pants" data-prefix="men">${data.Pants}</a>
            </li>
            ${generateNavLink({
              className: "fleece",
              href: "/c/mens/fleece",
              dataPrefix: "men",
              text: data.Fleece,
            })}
            <li class="base-layer">
                <a href="/c/mens/base-layer" data-prefix="men">${data["Base Layer"]}</a>
            </li>
            <li class="shirts-tops">
                <a href="/c/mens/shirts-and-tops" data-prefix="men">${data["Shirts & Tops"]}</a>
            </li>
          </ul>
          <ul>
            ${generateNavLink({
              className: "shorts",
              href: "/c/mens/shorts",
              dataPrefix: "men",
              text: data.Shorts,
              blockArr: [],
            })}
            ${generateNavLink({
              className: "packs",
              href: "/c/mens/packs",
              dataPrefix: "men",
              text: data.Packs,
              blockArr: [],
            })}
            <li class="footwear">
                <a href="/c/mens/footwear" data-prefix="men">${data.Footwear}</a>
            </li>
            <li class="climbing-gear">
                <a href="/c/mens/climbing-gear" data-prefix="men">${data["Climbing Gear"]}</a>
            </li>
            <li class="accessories">
                <a href="/c/mens/accessories" data-prefix="men">${data.Accessories}</a>
            </li>
            <li class="veilance CN-block">
              <a href="/c/mens/veilance" data-prefix="men" data-analyticsLabel="Veilance" data-linkid="men:collection:veilance">${
                data["Veilance Name"]
              }</a>
            </li>
          </ul>
        </div>

        <div class="sale__shop__links--mobile">
          <ul>
            <li class="">
              <a href="https://arcteryx.com">${data["Shop Arc'teryx"]}</a>
            </li>
            <li class="${EMEA_Block}">
              <a href="https://www.usedgear.arcteryx.com">${data["Shop Used Gear"]}</a>
            </li>
          </ul>
        </div>

        <div class="feature">
          <a class="content" href="/c/mens/insulated-jackets">
                <h6 data-prefix="men">${data["Insulated Jackets"]}</h6>
            </a>
        </div>

      </div>
  </div>`;
};

const womensNavDropdown = (data, country, language) => {
  if (!isNavDropdownEnabled(country)) {
    return "";
  }

  return `
  <div class="sub-menu">
    <div class="container">
      <div class="mobilefly">
        <ul>
          <li class="just-landed">
                <a href="/c/womens/just-landed/wid-39r1kkxj" data-prefix="women">${data["Just Landed"]}</a>
          </li>
          <li class="new-for-spring CA-block US-block ${EMEA_Block}">
              <a href="/c/womens/just-added" data-prefix="women">${data["Just Added"]}</a>
          </li>
          <li class="shell-jackets">
              <a href="/c/womens/shell-jackets" data-prefix="women">${data["Shell Jackets"]}</a>
          </li>
          <li class="insulated-jackets">
              <a href="/c/womens/insulated-jackets" data-prefix="women">${data["Insulated Jackets"]}</a>
          </li>
          <li class="pants">
              <a href="/c/womens/pants" data-prefix="women">${data.Pants}</a>
          </li>
          ${generateNavLink({
            className: "fleece",
            href: "/c/womens/fleece",
            dataPrefix: "women",
            text: data.Fleece,
          })}
          <li class="base-layer">
              <a href="/c/womens/base-layer" data-prefix="women">${data["Base Layer"]}</a>
          </li>
          <li class="shirts-tops">
              <a href="/c/womens/shirts-and-tops" data-prefix="women">${data["Shirts & Tops"]}</a>
          </li>
        </ul>
        <ul>
          <li class="shorts">
              <a href="/c/womens/shorts" data-prefix="women">${data.Shorts}</a>
          </li>
          ${generateNavLink({
            className: "dresses-skirts",
            href: "/c/womens/dresses-and-skirts",
            dataPrefix: "women",
            text: data["Dresses & Skirts"],
            blockArr: ["CA-only", "US-only", EMEA_Only],
          })}
          <li class="packs">
              <a href="/c/womens/packs" data-prefix="women">${data.Packs}</a>
          </li>
          <li class="footwear">
              <a href="/c/womens/footwear" data-prefix="women">${data.Footwear}</a>
          </li>
          ${generateNavLink({
            className: "climbing-gear",
            href: "/c/womens/climbing-gear",
            dataPrefix: "women",
            text: data["Climbing-Gear"],
            onlyArr: [],
          })}
          <li class="climbing-gear US-block CA-block ${EMEA_Block}">
              <a href="/c/womens/climbing-gear" data-prefix="women">${data["Climbing Gear"]}</a>
          </li>
          <li class="accessories">
              <a href="/c/womens/accessories" data-prefix="women">${data.Accessories}</a>
          </li>
          <li class="veilance">
            <a href="/c/womens/veilance" data-prefix="women" data-analyticsLabel="Veilance" data-linkid="women:collection:veilance">${
              data["Veilance Name"]
            }</a>
          </li>
        </ul>
        </div>

        <div class="sale__shop__links--mobile">
          <ul>
            <li class="">
              <a href="https://arcteryx.com">${data["Shop Arc'teryx"]}</a>
            </li>
            <li class="${EMEA_Block}">
              <a href="https://www.usedgear.arcteryx.com">${data["Shop Used Gear"]}</a>
            </li>
          </ul>
        </div>

        <div class="feature">
            <a class="content" href="/c/womens/just-added">
              <h6 data-prefix="women">${data["Just Added"]}</h6>
            </a>
        </div>
    </div>
  </div>`;
};

const headerHtml = ({ data, country, language }) => `<div class="SALE NOTLOGGEDIN">
  <style type="text/css">
      ${indexStyles}
  </style>

  ${universalNav(data, country, language)}

  <div class="header">
      <!-- Header Messaging Banner -->
      <div class="header-info">
          <div class="header-info-contents">
            <span class="JP-only" style="display: none !important;">
                <span>${data["free Shipping And Returns Msg JP"]}</span>
            </span>
            <span class="JP-block">
                <span class="header-info__message">
                </span>
            </span>
          </div>
      </div>

      <!-- / Header Checkout -->
      <div class="header__checkout-heading">
          <div class="checkout-heading">${data.Checkout}</div>
      </div>

      <!-- / Header Messaging Banner -->
      <div class="header-content__wrapper">
          <div class="header_content">
              <div class="header-content__left">
                  <div class="header-logo" style="display: none;">
                      <a class="logo" href="/" title="Arc'teryx Outlet"><span>Arc'teryx</span>${OUTLETLogo}${OUTLETLogoMobile}</a>
                  </div>
                  <nav>
                  <ul>
                      <li>
                          <span id="women" class="head">
                              <a href="/c/womens" data-prefix="women">${data.Womens}</a>
                          </span>
                          ${womensNavDropdown(data, country, language)}
                      </li>
                      <li>
                          <span id="men" class="head">
                              <a href="/c/mens" data-prefix="men">${data.Mens}</a>
                          </span>
                          ${mensNavDropdown(data, country, language)}
                      </li>                      
                  </ul>
              </nav>
              </div>


              <div class="header-content__right">
                  <div class="header-search" aria-label="Search">
                    <span class="search">${data.Search}</span>
                    <span class="icon-search"></span>
                  </div>
                  <div id="right-nav">
                      <cart>
                          <div id="cartSummary">
                            <a id="cartInfo" href="/cart" rel="noindex, nofollow">
                              <span id="cartItems"></span>
                              <span class="visually-hidden">View Cart</span>
                            </a>
                          </div>
                      </cart>
                      <span class="flag flag"></span>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </div>
  <!-- /header -->`;

export default headerHtml;
