import { getLanguageFromLangCode, isUserLoggedIn } from "./GeneralHelpers";

const OUTDOOR_GA_TRACKING_ID = "UA-283808-20";
const LEAF_GA_TRACKING_ID = "UA-283808-27";

export const ArcWebSettings = {
  init(market, season, year, lang, country) {
    const noArcObj = typeof ARCTERYX === "undefined";
    const isDevStage =
      location.hostname.match("qa") ||
      location.hostname.match("staging") ||
      location.hostname.match("preprod") ||
      location.hostname.match("localhost");
    const OUTDOOR_ADOBE_TRACKING_ID = isDevStage ? "arctdev2011" : "arctprod";

    if (typeof ARCTERYX === "undefined" || typeof ARCTERYX.WEBSITE_SETTINGS === "undefined") {
      let GA_TRACKING_ID = OUTDOOR_GA_TRACKING_ID;
      let ADOBE_TRACKING_ID = OUTDOOR_ADOBE_TRACKING_ID;
      let ARCTERYXECOMM = "ARCTERYXECOMM";
      const IS_LOGGED_IN = isUserLoggedIn(document, "body");
      if (market === "leaf") {
        GA_TRACKING_ID = LEAF_GA_TRACKING_ID;
        ADOBE_TRACKING_ID = "";
        ARCTERYXECOMM = "";
      }

      const ARCTERYX = {};
      ARCTERYX.WEBSITE_SETTINGS = {
        MARKET: market.toUpperCase(),
        SEASON: season.toUpperCase(),
        YEAR: year,
        LANGUAGE: getLanguageFromLangCode(lang),
        LANG: lang.toUpperCase(),
        COUNTRY: country.toUpperCase(),
        GA_TRACKING_ID,
        ADOBE_TRACKING_ID,
        ADOBE_TRACKING_ID_SYS_PARM: ADOBE_TRACKING_ID,
        ARCTERYX_ECOMM: ARCTERYXECOMM,
        IS_LOGGED_IN,
      };

      if (typeof ARCTERYX.analytics === "undefined") {
        ARCTERYX.analytics = {};
      }

      const arcScript = document.createElement("script");
      arcScript.type = "text/javascript";
      if (noArcObj) {
        arcScript.innerHTML = `var ARCTERYX = ${JSON.stringify(ARCTERYX)}`;
      } else {
        arcScript.innerHTML = `ARCTERYX.WEBSITE_SETTINGS = ${JSON.stringify(ARCTERYX.WEBSITE_SETTINGS)}`;
      }
      document.getElementsByTagName("head")[0].appendChild(arcScript);
    }
  },
};

export default ArcWebSettings;
