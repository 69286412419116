/* eslint-disable */
const Lightbox = function(elem, options) {
  const defaults = {
    openOnCreate: false,
    type: null,
    html: null,
  };
  const resolvedOptions = { ...defaults, ...options };

  this.trigger = elem;
  this.height = (elem && elem.getAttribute("lightbox-max-height")) || "700";
  this.width = (elem && elem.getAttribute("lightbox-max-width")) || "750";
  this.type = resolvedOptions.type || (elem && elem.getAttribute("lightbox"));
  this.href = (elem && (elem.getAttribute("url") || elem.getAttribute("href"))) || null;
  this.hideScroll = (elem && elem.getAttribute("lightbox-hidescroll")) || false;
  this.html = resolvedOptions.html;
  this.image = null;
  this.video = null;
  this.openOnCreate = resolvedOptions.openOnCreate;
  this.init();
};

Lightbox.prototype.init = function() {
  const _this = this;

  if (!this.el) {
    this.create();
    if (this.openOnCreate) {
      this.open();
    }
  }

  if (this.trigger) {
    this.trigger.addEventListener("click", e => {
      e.preventDefault();
      _this.open();
    });
  }
};

Lightbox.prototype.create = function() {
  const _this = this,
    cl = document.createElement("div"), // Close
    bd = document.createElement("div"); // Backdrop

  this.el = document.createElement("div");
  this.content = document.createElement("div");
  this.body = document.createElement("div");

  this.el.classList.add("lightbox");
  bd.classList.add("backdrop");
  cl.classList.add("close");
  this.content.classList.add("content");
  this.body.classList.add("body");

  cl.innerHTML = '<span class="fa fa-times">&times;</span>';

  this.el.appendChild(bd);
  this.content.appendChild(cl);
  this.content.appendChild(this.body);
  this.el.appendChild(this.content);
  document.body.appendChild(this.el);

  cl.addEventListener("click", () => {
    _this.close();
  });

  bd.addEventListener("click", () => {
    _this.close();
  });

  const f = function(e) {
    if (_this.isOpen()) {
      return;
    }
    _this.el.classList.remove("show");
    _this.body.innerHTML = "";
  };

  this.el.addEventListener("transitionend", f, false);
  this.el.addEventListener("webkitTransitionEnd", f, false);
  this.el.addEventListener("mozTransitionEnd", f, false);
  this.el.addEventListener("msTransitionEnd", f, false);
};

Lightbox.prototype.loadImage = function() {
  const _this = this;

  this.setDimensions(this.width, this.height);

  if (!this.image) {
    this.image = new Image();

    this.image.addEventListener("load", function() {
      const dim = _this.fitToSize(this.naturalWidth, this.naturalHeight, _this.width, _this.height);
      _this.setDimensions(dim.width, dim.height);
    });

    this.image.src = this.href;
  }

  this.body.appendChild(this.image);
};

Lightbox.prototype.loadVideo = function() {
  const _this = this;
  this.setDimensions(this.width, this.height);

  if (!this.video) {
    this.video = document.createElement("video");
    this.video.addEventListener("loadedmetadata", function() {
      const dim = _this.fitToSize(this.videoWidth, this.videoHeight, _this.width, _this.height);
      _this.setDimensions(dim.width, dim.height);
    });
    this.video.src = this.href;
    this.video.autoplay = true;
    this.video.controls = true;
  }

  this.body.appendChild(this.video);
};

Lightbox.prototype.loadIframe = function() {
  // Open in new window
  if (window.innerWidth <= 768 && !this.hideScroll) {
    window.location.href = `${this.href}?rurl=${window.location.href}`;
  }
  this.setDimensions(this.width, this.height);
  this.body.innerHTML = `<iframe src="${this.href}" frameborder="0" allowfullscreen seamless="seamless" hidescroll="${this.hideScroll}"></iframe>`;
  if (this.hideScroll) {
    this.body.classList.add("hide-scroll");
  }
};

Lightbox.prototype.loadHtml = function() {
  this.setDimensions(this.width, this.height);
  this.body.innerHTML = this.html;
  if (this.hideScroll) {
    this.body.classList.add("hide-scroll");
  }
};

Lightbox.prototype.open = function() {
  switch (this.type) {
    case "image":
      this.loadImage();
      break;
    case "video":
      this.loadVideo();
      break;
    case "html":
      this.loadHtml();
      break;
    default:
      this.loadIframe();
  }

  this.el.classList.add("show");
  this.el.offsetHeight; // Force render
  this.el.classList.add("open");
  document.body.classList.add("no-scroll");
};

Lightbox.prototype.close = function() {
  this.el.classList.remove("open");
  document.body.classList.remove("no-scroll");
};

Lightbox.prototype.isOpen = function() {
  return this.el.classList.contains("open");
};

Lightbox.prototype.setDimensions = function(w, h) {
  this.width = w;
  this.height = h;
  this.content.style.maxWidth = `${w}px`;
  this.content.style.maxHeight = `${h}px`;
};

Lightbox.prototype.fitToSize = function(w, h, maxW, maxH) {
  const r = h / w;

  if (w >= maxW && r <= 1) {
    w = maxW;
    h = w * r;
  } else if (h >= maxH) {
    h = maxH;
    w = h / r;
  }

  return {
    width: w,
    height: h,
  };
};

export default Lightbox;
